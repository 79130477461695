<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <v-radio-group
        v-model="partner.noIncomeTaxDeclaration"
        required
        :rules="rules.noIncomeTaxDeclaration"
        column
        @change="saveField('noIncomeTaxDeclaration', $event)"
      >
        <template v-slot:label>
          <span>
            Já declarou alguma vez o Imposto de Renda Pessoa Física
            <strong>(IRPF)</strong>?
          </span>
        </template>
        <v-radio label="Sim" :value="true"></v-radio>
        <v-radio label="Não" :value="false"></v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12" md="6" v-show="showTaxDeclaration">
      <v-text-field
        id="incomeTaxDeclaration"
        validate-on-blur
        v-model="partner.incomeTaxDeclaration"
        ref="incomeTaxDeclaration"
        v-mask="['##.##.##.##.##', '##.##.##.##.##-##']"
        :rules="rules.incomeTaxDeclaration"
        @change="saveField('incomeTaxDeclaration', $event)"
      >
        <template v-slot:label>
          Número do <strong>último</strong> recibo do IRPF
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="6" v-if="showTaxDeclaration">
      <v-text-field
        id="incomeTaxDeclarationSecond"
        validate-on-blur
        v-model="partner.incomeTaxDeclarationSecond"
        ref="incomeTaxDeclarationSecond"
        v-mask="['##.##.##.##.##', '##.##.##.##.##-##']"
        @change="saveField('incomeTaxDeclarationSecond', $event)"
      >
        <template v-slot:label>
          Número do <strong>penúltimo</strong> recibo do IRPF
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" sm="12" md="4" v-if="showTituloEleitor">
      <v-text-field
        id="tituloEleitor"
        validate-on-blur
        label="Número do título de eleitor"
        v-model="partner.tituloEleitor"
        maxlength="12"
        :rules="rules.tituloEleitor"
        @change="saveField('tituloEleitor', $event)"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    partner: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      rules: {
        incomeTaxDeclaration: [
          (v) => {
            if (!this.partner.noIncomeTaxDeclaration) {
              return true
            } else {
              if (!v) {
                ;('Preencha o número da última declaração do imposto de renda.')
              }
              v += ''
              return (
                (v.length >= 14 && v.length <= 17) ||
                'O número da última declaração deverá ter 10 a 12 dígitos'
              )
            }
          },
        ],
        tituloEleitor: [
          (v) => {
            if (!!this.partner.noIncomeTaxDeclaration) {
              return true
            }
            return !!v || 'Preencha com título de eleitor.'
          },
        ],
        noIncomeTaxDeclaration: [
          (v) => v != null || 'Selecione uma das opções.',
        ],
      },
      showTituloEleitor: !this.partner.noIncomeTaxDeclaration,
      showTaxDeclaration: !!this.partner.noIncomeTaxDeclaration,
    }
  },
  computed: {},
  methods: {
    saveField(key, value) {
      if (key === 'noIncomeTaxDeclaration') this.show()
      this.$store.dispatch('partner/savePartnerField', {
        id: this.partner.id,
        [key]: value,
      })
    },
    show() {
      setTimeout(() => {
        this.showTituloEleitor = !this.partner.noIncomeTaxDeclaration
      }, 15)
      setTimeout(() => {
        this.showTaxDeclaration = !!this.partner.noIncomeTaxDeclaration
      }, 15)
    },
  },
}
</script>
