export const text = [(v) => !!v || 'Por favor preencha este campo']

export const fullName = [
  (v) => !!v || 'Por favor preencha o nome.',
  (v) =>
    /^[a-záàâãéèêíïóôõöúçñ]{2,} [a-záàâãéèêíïóôõöúçñ]{1,}.*?$/i.test(v) ||
    'Preencha o nome completo.',
]

export const email = [
  (v) => !!v || 'Por favor preencha o email.',
  (v) =>
    /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
    'Email precisa ser válido.',
]

export const occupation = [
  (v) => !!v || 'Por favor preencha a sua ocupação ou profissão.',
]

export const percentageHolding = [
  (v) =>
    !!v || 'Por favor preencha a porcentagem de sua participação na sociedade.',
  (v) =>
    (v > 0.009 && v <= 100) || 'Por favor preencha um valor de 0.01 a 100.',
]

export const address = [(v) => !!v || 'Preencha o endereço.']

export const addressNumber = [(v) => !!v || 'Preencha o número.']

export const gender = [(v) => !!v || 'Escolha o sexo.']

export const civilStatus = [(v) => !!v || 'Escolha o estado civil.']

export const state = [(v) => !!v || 'Selecione um estado.']

export const city = [(v) => !!v || 'Selecione uma cidade.']

export const district = [(v) => !!v || 'Preencha o bairro.']

export default text
