<template>
  <v-dialog v-model="localValue" v-if="localValue" persistent max-width="290">
    <v-card>
      <v-card-title class="custom-title-secondary">Deletar sócio</v-card-title>
      <v-card-text class="custom-normal-text ">
        Deseja deletar o sócio: <strong>{{ partner.fullName }}</strong> ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="custom-btn-green rounden-0"
          @click="deletePartner()"
          :loading="tryingToDelete"
        >
          Deletar
        </v-btn>
        <v-btn
          class="custom-btn-green-inverse rounden-0"
          @click="localValue = false"
          :disabled="tryingToDelete"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
      type: Boolean,
      defalt: () => {
        return false
      },
    },
    partner: {
      required: true,
      type: Object,
      defalt: () => {
        return {}
      },
    },
    goRouteOnDelete: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      tryingToDelete: false,
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    async deletePartner() {
      this.tryingToDelete = true
      await this.$store.dispatch('partner/deletePartner', this.partner.id)
      this.tryingToDelete = false
      this.$router.push(this.goRouteOnDelete)
      this.localValue = false
    },
  },
}
</script>
<style lang="scss" module>
@import '@design';
</style>
