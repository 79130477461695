<template>
  <v-row>
    <v-col cols="12" sm="4" md="3">
      <v-text-field
        ref="cep"
        validate-on-blur
        label="CEP"
        v-model="partner.cep"
        :rules="rules.cep"
        v-mask="cepMask"
        @change="searchCEP"
        required
        :loading="loadingCep"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="8" md="6">
      <v-text-field
        validate-on-blur
        label="Rua, Avenida, Alameda..."
        hint="Preencha o endereço"
        v-model="partner.address"
        :rules="rules.address"
        :loading="loadingCep"
        :readonly="loadingCep"
        required
        @change="saveField('address', $event)"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="4" md="3">
      <v-text-field
        validate-on-blur
        label="Número"
        hint="Número do endereço"
        v-model="partner.addressNumber"
        :rules="rules.addressNumber"
        required
        @change="saveField('addressNumber', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="6">
      <v-text-field
        validate-on-blur
        label="Complemento"
        hint="O complemento do endereço, exemplo: AP12"
        v-model="partner.addressComplement"
        @change="saveField('addressComplement', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="6">
      <v-text-field
        validate-on-blur
        label="Bairro"
        v-model="partner.district"
        :rules="rules.district"
        :loading="loadingCep"
        required
        @change="saveField('district', $event)"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="6">
      <v-select
        label="Estado"
        item-text="name"
        item-value="id"
        v-model="partner.idState"
        :items="states"
        :loading="loadingCep"
        :rules="rules.idState"
        required
        @change="stateChanged($event)"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6" md="6">
      <v-select
        label="Cidade"
        v-model="partner.idCity"
        :items="cities"
        :rules="rules.idCity"
        :loading="loadingCep"
        item-text="name"
        item-value="id"
        required
        @change="saveField('idCity', $event)"
      ></v-select>
    </v-col>
  </v-row>
</template>
<script>
import {
  cepMethods,
  cepComputed,
  stateMethods,
  stateComputed,
  cityMethods,
  cityComputed,
} from '@state/helpers'

export default {
  name: 'partner-address',
  props: {
    partner: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      rules: {
        cep: [(v) => !!v || 'Preencha com o cep.'],
        addressNumber: [(v) => !!v || 'Preencha com o numero.'],
        state: [(v) => !!v || 'Selecione um estado.'],
        city: [(v) => !!v || 'Selecione uma Cidade.'],
      },
      loadingCep: false,
    }
  },
  computed: {
    ...cepComputed,
    ...stateComputed,
    ...cityComputed,
  },
  mounted() {
    this.getCities({ stateId: this.partner.idState })
    this.getAllStates()
  },
  methods: {
    ...cepMethods,
    ...stateMethods,
    ...cityMethods,
    saveField(key, value) {
      if (key === 'noIncomeTaxDeclaration') this.show()
      this.$store.dispatch('partner/savePartnerField', {
        id: this.partner.id,
        [key]: value,
      })
    },
    stateChanged(v) {
      if (!v) return false
      this.saveField('idState', v)
      this.getCities({ stateId: v })
    },
    searchCEP(v) {
      if (!v) return false
      this.loadingCep = true
      const value = this.$options.filters.unmaskText(v)
      this.search(value)
        .then((resp) => {
          let data = {
            id: this.partner.id,
            idState: resp.idState,
            idCity: resp.idCity,
            address: resp.logradouro,
            district: resp.bairro,
            cep: value,
            cepNotFound: false,
          }
          this.getCities({ stateId: data.idState })
          this.loadingCep = false
          this.$store.dispatch('partner/savePartnerField', data)
        })
        .catch((err) => {
          this.loadingCep = false
          this.saveField('cepNotFound', true)
        })
    },
  },
}
</script>
