<template>
  <div>
    <v-form ref="form" v-model="valid" autocomplete="off" :id="partner.id">
      <v-col cols="12" class="text-right" v-if="partners.length > 1">
        <span>Excluir sócio</span>
        <v-btn
          text
          icon
          dark
          color="error"
          @click="tryingDeletePartner = !tryingDeletePartner"
        >
          <v-icon dark>remove_circle_outline</v-icon>
        </v-btn>
      </v-col>
      <form-basic-info v-if="tryingToGetPartner" :partner="partner" />
      <form-address v-if="tryingToGetPartner" :partner="partner" />
    </v-form>
    <v-card-actions>
      <v-row justify="center">
        <v-col class="text-center">
          <v-btn
            class="custom-btn-green rounded-0"
            @click="goToCall()"
            :loading="partnerLoadingRg"
          >
            Salvar informações e avançar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <dialogDelete
      v-if="tryingToGetPartner"
      v-model="tryingDeletePartner"
      :partner="partner"
      :goRouteOnDelete="{ name: 'partner', params: { id: firstPartnerId } }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import formBasicInfo from './formBasicInfo.vue'
import formAddress from './formAddress.vue'
import dialogDelete from './dialogDelete.vue'

import {
  companyMethods,
  companyComputed,
  partnerMethods,
  partnerComputed,
  authMethods,
  authComputed,
  formCompanyPartnerMethods,
  formCompanyPartnerComputed,
} from '@state/helpers'

export default {
  components: {
    formBasicInfo,
    formAddress,
    dialogDelete,
  },
  data() {
    return {
      valid: false,
      tryingToGetPartner: false,
      tryingDeletePartner: false,
    }
  },
  computed: {
    ...partnerComputed,
    ...companyComputed,
    ...authComputed,
    ...formCompanyPartnerComputed,
    currentIdPartner() {
      return this.$route.params.id
    },
    firstPartnerId() {
      return this.partners[0] ? this.partners[0].id : null
    },
  },
  methods: {
    ...companyMethods,
    ...partnerMethods,
    ...authMethods,
    ...formCompanyPartnerMethods,
    async setPartner() {
      this.tryingToGetPartner = false
      await this.fetchPartner(this.currentIdPartner)
      this.tryingToGetPartner = true
    },
    goToCall() {
      if (this.company.hasPartner) {
        this.goToNextPartner()
        return
      }
      this.goToNextStep()
    },
    async goToNextPartner() {
      if (this.partners.length === 1) {
        this.createPartnerAndGoToNewPartner()
        return
      }
      if (this.forceValidationPartners) {
        await this.forceValidation()
        let isValid = this.checkIfPartnerIsValidById(this.partners.id)
        if (isValid) this.goToNextStep()
        this.$refs.form.validate()
        this.scrollTop()
        return
      }

      let nextPartner = this.getNextPartner()
      if (nextPartner) {
        this.$router.push({ name: 'partner', params: { id: nextPartner.id } })
        return
      }
      this.goToNextStep()
    },
    async goToNextStep() {
      console.log('goToNextStep')
      await this.forceValidation()
      let validForm = this.$refs.form.validate()
      console.log(validForm, this.companyIsValid, this.allPartnersIsValid)
      if (!validForm || !this.companyIsValid || !this.allPartnersIsValid) {
        this.scrollTop()
        return
      }
      //go to call
      await this.updateUser({ idStepRegistration: 5 })
      this.$router.push({ name: 'call' })
      return
    },
    scrollTop() {
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: 'linear',
      })
    },
    createPartnerAndGoToNewPartner() {
      this.createPartner({}).then((newPartner) => {
        this.$router.push({ name: 'partner', params: { id: newPartner.id } })
      })
    },
    getNextPartner() {
      let indexPartner = this.partners.findIndex((p) => p.id == this.partner.id)
      let nextPartner = this.partners[indexPartner + 1]
      return nextPartner
    },
  },
  async created() {
    this.setPartner()
    this.$nextTick(async () => {
      if (this.forceValidationPartners) {
        await this.forceValidation()
        this.$refs.form.validate()
      }
    })
  },
}
</script>

<style lang="scss" module>
@import '@design';
</style>
