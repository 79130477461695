<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        validate-on-blur
        label="Nome completo"
        required
        v-model="partner.fullName"
        :rules="rules.fullName"
        lazy
        @change="saveField('fullName', $event)"
      ></v-text-field>
    </v-col>

    <v-col cols="11" v-if="!!company.hasPartner">
      <v-radio-group
        v-model="partner.accountable"
        @change="saveField('accountable', $event)"
        required
        column
      >
        <template v-slot:label>
          <span class="mb-2"> Responsável legal pela empresa? </span>
          <base-bullet-info
            class="ml-1"
            href="https://simplificador.com.br/blog/socio-administrador-e-socio-quotista/"
          ></base-bullet-info>
        </template>
        <v-radio label="Sim" :value="true"></v-radio>
        <v-radio label="Não" :value="false"></v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12" sm="6">
      <v-select
        :items="['Masculino', 'Feminino']"
        v-model="partner.gender"
        label="Sexo"
        single-line
        required
        :rules="rules.gender"
        @change="saveField('gender', $event)"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6">
      <v-select
        :items="['Solteiro(a)', 'Casado(a)', 'Divorciado(a)', 'Viúvo(a)']"
        v-model="partner.civilStatus"
        label="Estado Civil"
        single-line
        :rules="rules.civilStatus"
        required
        @change="saveField('civilStatus', $event)"
        validate-on-blur
      ></v-select>
    </v-col>

    <v-col cols="12" v-show="isMarried">
      <v-select
        :items="matrimonialRegime"
        v-model="partner.idMatrimonialRegime"
        label="Regime do Casamento"
        item-text="regime"
        item-value="id"
        single-line
        required
        :rules="rules.matrimonialRegime"
        @change="saveField('idMatrimonialRegime', $event)"
        validate-on-blur
      ></v-select>
    </v-col>

    <v-col cols="12">
      <span>Email</span>
      <v-tooltip right v-show="partner.accountUser">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">info</v-icon>
        </template>
        <span>O seu email será o mesmo usado para login.</span>
      </v-tooltip>
      <v-text-field
        validate-on-blur
        required
        label="e-mail"
        :rules="rules.email"
        :readonly="partner.accountUser"
        v-model="partner.email"
        @change="saveField('email', $event)"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field
        validate-on-blur
        label="Telefone"
        required
        v-mask="['(##) #####-####', '(##) ####-####']"
        v-model="partner.phone"
        :rules="rules.phone"
        ref="phone"
        @change="saveField('phone', $options.filters.unmaskText($event))"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field
        validate-on-blur
        label="Ocupação/Profissão"
        required
        @change="saveField('occupation', $event)"
        v-model="partner.occupation"
        :rules="rules.occupation"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <input-file
        ref="rg"
        label="Imagem do RG"
        label-send="O RG foi enviado com sucesso!"
        hint="A imagem precisa conter os dois lados do RG para estar legível."
        required
        :rules="rules.idRg"
        v-model="partner.idRg"
        @change="saveRG($event)"
      ></input-file>
    </v-col>

    <v-col cols="12">
      <v-text-field
        validate-on-blur
        label="Número do CPF"
        required
        v-model="partner.cpf"
        :rules="rules.cpf"
        v-mask="'###.###.###-##'"
        ref="cpf"
        @change="saveField('cpf', $options.filters.unmaskText($event))"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <field-titulo-eleitor-or-tax-declaration :partner="partner" />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

import InputFile from '@src/components/widget/InputFile.vue'
import fieldTituloEleitorOrTaxDeclaration from './fieldTituloEleitorOrTaxDeclaration.vue'
import { fullName } from '@src/services/rules'

import {
  companyComputed,
  partnerMethods,
  partnerComputed,
} from '@state/helpers'

export default {
  components: {
    InputFile,
    fieldTituloEleitorOrTaxDeclaration,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        fullName,
        email: [(v) => !!v || 'Por favor preencha o email.'],
        occupation: [
          (v) => !!v || 'Por favor preencha a sua ocupação ou profissão.',
        ],
        matrimonialRegime: [
          (v) => {
            if (this.partner.civilStatus !== 'Casado(a)') return true

            return !!v || 'Selecione um regime de casamento.'
          },
        ],
        idRg: [(v) => v != null || 'Selecione uma RG.'],
        phone: [
          (v) => !!v || 'Por favor preencha o telefone.',
          (v) => {
            v = v || ''
            return (
              v.length > 9 ||
              'O número de telefone precisa ter mais 10 digitos.'
            )
          },
        ],
      },
    }
  },
  computed: {
    ...companyComputed,
    ...mapGetters({
      matrimonialRegime: 'matrimonialRegime/matrimonialRegime',
    }),
    isMarried() {
      return this.partner.civilStatus === 'Casado(a)'
    },
  },
  methods: {
    ...partnerMethods,
    async saveField(key, value) {
      await this.savePartnerField({ id: this.partner.id, [key]: value })
      this.$nextTick(() => {
        if (key === 'cpf') this.$refs.cpf.$emit('input')
        if (key === 'phone') this.$refs.phone.$emit('input')
      })
    },
    saveRG(file) {
      this.$store.dispatch('taxonomy/savePartnerRG', {
        idPartner: this.partner.id,
        file: file,
      })
    },
  },
}
</script>
<style lang="sass" scoped></style>
